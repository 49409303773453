import Header from './components/Header';
import './App.css';
import RegistrarProducto from './components/RegistrarProducto';

function App() {
  
  return (
    <div className="App">
        <Header />
        <RegistrarProducto />
        

    </div>
  );
}

export default App;