import React, { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../accesos/API"; // Asegúrate de cambiar esto a la ruta del archivo donde se encuentra la variable API
import TablaProductos from "./RegistrarProducto/TablaProductos";
import EditarProductoModal from "./RegistrarProducto/EditarProductoModal";

import "./RegistrarProducto.css"
import UploadDeleteFiles from "./RegistrarProducto/UploadDeleteFiles";

function RegistrarProducto() {
  const [datos, setDatos] = useState([{"id": "1", "nombre": "Producto 1", "precio": "100", "estado": "Estado 1", "categoria": "Categoria 1", "descripcion": "Descripcion 1", "imagen": "https://velimaq.smartaccesorios.shop/zimages/1/1.jpg"}]);
  const [productoEditar, setProductoEditar] = useState(null);
  const [imagenEditar, setImagenEditar] = useState(null);
  const [actualiza, setActualiza] = useState(false);

  useEffect(() => {
    axios
      .get(API)
      .then((res) => {
        setDatos(res.data);
      })
      .catch((error) => {
        console.error(`Error: ${error}`);
      });
  }, [actualiza]);

  const abrirEditarProducto = (producto) => {
    setProductoEditar(producto);
  };
  const cerrarEditar = () => {
    setProductoEditar(null);
  };

  const abrirEditarImagen = (producto) => {
    setImagenEditar(producto);
  };
  const cerrarImagenEditar = () => {
    setImagenEditar(null);
    setActualiza(!actualiza);
  };



  const actualizarProducto = (productoActualizado) => {
      console.log(productoActualizado)
      if (productoActualizado.id) {
          axios      
            .put(`${API}?id=${productoActualizado.id}`, productoActualizado)
            .then((res) => {
              console.log(res)
              setDatos(
                datos.map((dato) =>
                  dato.id === productoActualizado.id ? productoActualizado : dato
                )
              );
              cerrarEditar();
            })
            .catch((error) => {
              console.error(`Error: ${error}`);
            });
      } else {
          productoActualizado.imagen = "https://www.shutterstock.com/image-vector/vector-illustration-male-silhouette-profile-260nw-1053115607.jpg";

          axios
            .post(API, productoActualizado)
            .then((res) => {
              console.log(res.data);
              cerrarEditar();
              setActualiza(!actualiza);
            })
            .catch((error) => {
              console.error(`Error: ${error}`);
            });
      }
  };



  return (
      <div className="mt-24 flex flex-col items-end">
        <div className="w-full flex justify-between items-center mb-4">
          <h1 className="ml-5 lg:text-2xl lg:ml-20 text-lg font-semibold text-gray-700">Lista de Productos</h1>
          <button
            className="mr-5 lg:mr-20 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            onClick={() => abrirEditarProducto({})}
          >
            +Producto
          </button>
        </div>
        <div className="tableContainer flex justify-center items-center w-full">
          <TablaProductos productos={datos} abrirEProducto={abrirEditarProducto} abrirEImagen={abrirEditarImagen}/>
          {productoEditar && (
            <EditarProductoModal
              productoEditar={productoEditar}
              cerrarEditar={cerrarEditar}
              actualizarProducto={actualizarProducto}
            />
          )}
          {imagenEditar && (
            <UploadDeleteFiles
              imagenEditar={imagenEditar}
              actualiza = {actualiza}
              setActualiza = {setActualiza}
              cerrarImagenEditar={cerrarImagenEditar}
              actualizarProducto={actualizarProducto}
            />
          )}
        </div>
      </div>
    );
}

export default RegistrarProducto;
