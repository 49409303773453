import React, { useState } from "react";

function Producto({ index, producto, abrirEProducto, abrirEImagen  }) {
  const [imagenSeleccionada, setImagenSeleccionada] = useState(null);
  const [indiceImagen, setIndiceImagen] = useState(0);

  const abrirImagen = (indice) => {
    setImagenSeleccionada(producto.imagen.split(","));
    setIndiceImagen(indice);
  };

  const cerrarImagen = () => {
    setImagenSeleccionada(null);
  };

  const siguienteImagen = () => {
    setIndiceImagen((indiceImagen + 1) % producto.imagen.split(",").length);
  };

  const anteriorImagen = () => {
    setIndiceImagen(
      (indiceImagen - 1 + producto.imagen.split(",").length) %
        producto.imagen.split(",").length
    );
  };

  return (
    <tr className={index % 2 === 0 ? "bg-gray-100" : ""}>
      <td className="px-4 py-2">{producto.nombre}</td>
      <td className="px-4 py-2">{producto.precio}</td>
      <td className="px-4 py-2">{producto.estado}</td>
      <td className="px-4 py-2">{producto.categoria}</td>
      <td className="px-4 py-2" title={producto.descripcion}>
        {producto.descripcion.length > 50
          ? `${producto.descripcion.substring(0, 50)}...`
          : producto.descripcion}
      </td>
      <td className="py-2">
        <div className="flex flex-wrap max-w-full">
          {producto.imagen.split(",").map((imagen, index) => (
            <img
              key={index}
              src={imagen}
              alt={producto.nombre + index}
              className="w-6 h-5 object-cover cursor-pointer m-0.5"
              onClick={() => abrirImagen(index)}
            />
          ))}
        </div>
      </td>
      <td>
        <button
          className="my-1 bg-blue-500 text-white px-2 py-2 rounded"
          onClick={() => abrirEProducto(producto)}
        >
          E.Producto
        </button>
      
        <button
          className="mb-1 bg-green-500 text-white px-2 py-2 rounded"
          onClick={() => abrirEImagen(producto)}
        >
          E.Imagenes
        </button>
      </td>
      {imagenSeleccionada && (
        <ImagenModal
          imagenes={imagenSeleccionada}
          indiceImagen={indiceImagen}
          cerrarImagen={cerrarImagen}
          siguienteImagen={siguienteImagen}
          anteriorImagen={anteriorImagen}
        />
      )}
    </tr>
  );
}

export default Producto;

function ImagenModal({
  imagenes,
  indiceImagen,
  cerrarImagen,
  siguienteImagen,
  anteriorImagen,
}) {
  const manejarClick = (e, callback) => {
    e.stopPropagation();
    callback();
  };

  return (
    <div
      className="fixed inset-0 bg-black/70 flex items-center justify-center z-50"
      onClick={cerrarImagen}
    >
      <div style={{ maxWidth: "90%" }} className="relative  max-w-9/10 mx-auto">
        <img
          src={imagenes[indiceImagen]}
          alt="Imagen seleccionada"
          className="w-full h-auto transition duration-1000 ease-in-out"
        />
        <button
          className="absolute top-1 right-1 bg-red-500/55 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
          onClick={cerrarImagen}
        >
          X
        </button>
      </div>
      <div className="absolute bottom-40 left-50 rounded-lg bg-white text-black p-2">
        <button
          className="mx-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded"
          onClick={(e) => manejarClick(e, anteriorImagen)}
        >
          Anterior
        </button>
        <span className="mx-3">
          {indiceImagen + 1} de {imagenes.length}
        </span>
        <button
          className="mx-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded"
          onClick={(e) => manejarClick(e, siguienteImagen)}
        >
          Siguiente
        </button>
      </div>
    </div>
  );
}
