// Header.js
import React from "react";
import logo from "../multimedia/logo.png";
import "./Header.css";

const Header = () => {



  return (
    <div className="fixed h-20 top-0 left-0 right-0 bg-gradient-to-r from-green-400 to-blue-500 flex px-2 py-0 justify-between items-center">
      <div className="mx-auto flex justify-center items-center ">

      <img src={logo} alt="Logo" className="h-14 pl-2 mx-5" />

      </div>
      
    </div>
  );
};

export default Header;
