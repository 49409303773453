// TablaProductos.js
import React  from 'react';
import Producto from './Producto';


function TablaProductos({ productos, abrirEImagen, abrirEProducto }) {

  var espaciado = " py-2";
    return (
      <div style={{maxWidth:"90%", maxHeight:"85vh", overflowX: 'auto', overflowY:"auto"}} >
        <table style={{}} className='text-xs lg:text-sm table-auto bg-white rounded-lg shadow-md overflow-hidden w-full max-w-screen-lg'>
        <thead className='bg-gray-800 text-white'>
          <tr>
            <th className={espaciado}>Nombre</th>
            <th className={espaciado}>Precio</th>
            <th className={espaciado}>Estado</th>
            <th className={espaciado}>Categoria</th>
            <th className={espaciado + "px-20"}>Descripcion</th>
            <th className={espaciado}>Imagen</th>
            <th className={espaciado}>Acciones</th>
          </tr>
        </thead>
        <tbody>
        {productos.map((producto, index) => (
          <Producto key={index} producto={producto} index={index} abrirEImagen={abrirEImagen} abrirEProducto={abrirEProducto} />
        ))}
        </tbody>
      </table>
      </div>
    );
  }
export default TablaProductos;